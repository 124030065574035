<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-tabs color="deep-purple accent-4">
            <v-tab>Pending</v-tab>

            <v-tab>Approved</v-tab>

            <!-- this is for Approved Tranfer -->
            <v-tab-item>
              <div>
                <GroupPendingChalan :id="$route.query.id"></GroupPendingChalan>
              </div>
            </v-tab-item>
            <v-tab-item>
              <v-container>
                <v-card class="ml-5 mt-15">
                  <v-data-table
                    :headers="approved_products_headers"
                    :items="group_products"
                    :search="searchpending"
                    :loading="loading"
                    loading-text="Please wait..."
                    item-key="id"
                    class="elevation-1"
                    style="background: #f5f5f5"
                  >
                    <template v-slot:top>
                      <v-toolbar
                        elevation="2"
                        style="background: #f5f5f5; font-size: 26px"
                      >
                        <v-spacer></v-spacer>
                        <div class="pr-5 pb-5 pt-2">
                          <v-text-field
                            v-model="searchpending"
                            append-icon="mdi-magnify"
                            label="Search"
                            filled
                            rounded
                            dense
                            hide-details
                          ></v-text-field>
                        </div>
                      </v-toolbar>
                    </template>
                  </v-data-table>
                </v-card>
              </v-container>
            </v-tab-item>
          </v-tabs>
          <v-dialog
            v-model="dialog"
            transition="scale-transition"
            origin="top right"
            :nudge-left="500"
            max-width="900px"
            max-height="650"
          >
            <v-card class="pa-5">
              <v-card class="pa-5" elevation="0">
                <v-app-bar absolute color="white" flat>
                  <v-toolbar-title class="font-weight-bold"
                    >Products Detail</v-toolbar-title
                  >

                  <v-spacer></v-spacer>
                  <v-btn
                    class=""
                    icon
                    elevation="0"
                    @click="closedialog"
                    color="red"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-app-bar>

                <v-card class="mx-auto mt-12" elevation="0">
                  <v-row
                    v-if="
                      child_details.Product_image &&
                      child_details.Product_image.length > 0
                    "
                  >
                    <v-carousel
                      cycle
                      height="400"
                      hide-delimiter-background
                      show-arrows-on-hover
                    >
                      <v-carousel-item
                        v-for="(slide, i) in child_details.Product_image"
                        :key="i"
                        :src="slide.image_url"
                      >
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center"
                        >
                        </v-row>
                      </v-carousel-item>
                    </v-carousel>
                  </v-row>

                  <v-card-text>
                    <v-row align="center">
                      <v-col cols="4">
                        <p class="font-weight-medium">
                          Product ID :
                          <span class="font-weight-regular">{{
                            child_details.product_specification.product_id
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="4">
                        <p class="font-weight-medium"></p>
                      </v-col>
                      <v-col cols="4">
                        <p class="font-weight-medium">
                          SKU :
                          <span class="font-weight-regular">{{
                            child_details.Product_code.SKU
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="12"
                        ><p class="font-weight-medium">
                          Product Title :
                          <span class="font-weight-regular">{{
                            child_details.Product.title
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Brand :
                          <span class="font-weight-regular"
                            >{{ child_details.Product.brand }}
                          </span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Unit :
                          <span class="font-weight-regular">{{
                            child_details.product_specification.unit
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-medium">
                          Origin :
                          <span class="font-weight-regular">{{
                            child_details.Product.origin
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="6"
                        ><p class="font-weight-medium">
                          Shipping Country :
                          <span class="font-weight-regular">{{
                            child_details.Product.shipping_country
                          }}</span>
                        </p>
                      </v-col>

                      <v-col cols="12">
                        <p class="font-weight-medium">Product Description</p>
                        {{ child_details.Product.description }}
                      </v-col>
                      <v-col cols="6">
                        Color :

                        {{ child_details.product_specification.color }}
                      </v-col>
                      <v-col cols="6" class="pa-5">
                        Size :

                        {{ child_details.product_specification.size }}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card>
            </v-card>
            <v-card class="pa-5">
              <v-card-text class="pt-0">
                <v-form ref="specPrice" v-model="valid_add_price_specification">
                  <v-row>
                    <v-card style="min-width: 100%; border: 1px solid black">
                      <v-card-title class="font-weight-medium pt-2">
                        <p class="mt-5">
                          Total Quantity: {{ child_details.quantity }}
                        </p></v-card-title
                      >
                      <v-card-text>
                        <v-simple-table fixed-header>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-center">Status</th>
                                <th class="text-center">Quantity</th>
                              </tr>
                            </thead>
                            <tbody align="center">
                              <tr
                                v-for="(
                                  item, index
                                ) in child_details.status_data"
                                :key="index"
                              >
                                <td class="text-center">
                                  {{ item.status }}
                                </td>
                                <td class="text-center" width="200">
                                  <v-text-field
                                    v-model="item.quantity"
                                    outlined
                                    dense
                                    class="mt-5"
                                    :rules="[
                                      () =>
                                        child_details.quantity >=
                                          item.quantity ||
                                        'Please Decrease Quantity',
                                    ]"
                                  ></v-text-field>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card-text>
                    </v-card>
                  </v-row>
                  <v-card-actions>
                    <v-row>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="d-flex flex-row mt-2">
                        <v-btn
                          elevation="0"
                          color="red"
                          @click="CancelApprove(child_details)"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          @click="SaveApprove(child_details)"
                          elevation="0"
                          color="success"
                          :disabled="!valid_add_price_specification"
                        >
                          Approve
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog v-model="invoicedialog" max-width="900px">
            <v-row>
              <v-col cols="12">
                <v-card class="mt-10 mb-10" elevation="0">
                  <v-data-table
                    :headers="invoiceHeader"
                    :items="invoiceinfoarrayafter"
                    class="elevation-1"
                    style="background: #f5f5f5"
                  >
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" centered :color="color">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
export default {
  components: {
    GroupPendingChalan: () =>
      import("@/components/manager/GroupPendingChalan.vue"),
  },

  data: () => ({
    expanded: [],
    search: "",
    selected: [],
    AllAdminProductArray: [],
    invoicedialog: false,
    singleSelect: false,
    requested_qty: 0,
    Transfersavedialog: false,
    singleExpand: false,
    loading: true,
    AllWarehouseList: [],
    trnasferob: {},
    request_setter: "",

    text: "",
    color: "",
    TransitProductArray: [],
    valid_add_price_specification: false,
    snackbar: false,
    search: "",

    dialog: false,
    dialogapproved: false,
    orders: [],
    quantity: 0,
    user: {},
    contact: {},
    sellerPdetails: {},
    child_details: {
      category_data: {},
      Product_code: {},
      Product_image: {},
      product_specification: {},
      Product: {},
      status_data: [
        {
          status: "Approved",
          quantity: 0,
        },
        {
          status: "Not Found",
          quantity: 0,
        },
        {
          status: "Damaged",
          quantity: 0,
        },
      ],
    },

    all_shared_product: [],
    approved_product: [],
    pending_product: [],
    cancelled_product: [],

    approve_headers: [
      {
        text: "Specification ID",
        value: "id",
      },
      { text: "Product Name", value: "product_data.title", sortable: false },
      { text: "Brand", value: "product_data.brand", sortable: false },
      { text: "Color", value: "color", sortable: false },
      { text: "Size", value: "size", sortable: false },
      { text: "Details", value: "actions", sortable: false },
    ],
    headerspending: [
      { text: "Product Name", value: "Product.title", sortable: false },

      { text: "Brand", value: "Product.brand", sortable: false },
      { text: "Color", value: "product_specification.color", sortable: false },
      { text: "Size", value: "product_specification.size", sortable: false },
      { text: "Details", value: "actionsview", sortable: false },
    ],
    TransitHeader: [
      { text: "Product Name", value: "Product.title", sortable: false },
      { text: "Brand", value: "Product.brand", sortable: false },
      { text: "Color", value: "product_specification.color", sortable: false },
      { text: "Size", value: "product_specification.size", sortable: false },
      { text: "Available Quantity", value: "quantity", sortable: false },
      { text: "Quantity", value: "actionsqty", sortable: false },
    ],
    TransitModelHeader: [
      { text: "Product Name", value: "Product.title", sortable: false },
      { text: "Brand", value: "Product.brand", sortable: false },
      { text: "Color", value: "product_specification.color", sortable: false },
      { text: "Size", value: "product_specification.size", sortable: false },
      { text: "Available Quantity", value: "quantity", sortable: false },
      { text: "Quantity", value: "actionsqty", sortable: false },
    ],
    TransferInvoice: [
      { text: "Date", value: "date", sortable: false },
      { text: " Name", value: "getter_name", sortable: false },
      { text: "Loaction", value: "getter_location", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Invoice", value: "actionviewInvoice", sortable: false },
    ],
    requested_pending_product_headers: [
      { text: " Date ", value: "date", sortable: false },
      { text: " House Name", value: "name", sortable: false },
      { text: "Loaction", value: "locaton", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Approve", value: "actionsapprovereq", sortable: false },
    ],
    invoiceHeader: [
      { text: "Product Name", value: "Product[0].title", sortable: false },
      { text: "Brand", value: "Product.brand", sortable: false },
      { text: "Color", value: "product_specification.color", sortable: false },
      { text: "Size", value: "product_specification.size", sortable: false },
      { text: "Available Quantity", value: "quantity", sortable: false },
      { text: "Quantity", value: "actionsqty", sortable: false },
    ],
    headerAdminProducts: [
      { text: "SKU", value: "Product_code.SKU", sortable: false },
      { text: "Product Name", value: "Product.title", sortable: false },
      { text: "Brand", value: "Product.brand", sortable: false },
      { text: "Color", value: "product_specification.color", sortable: false },
      { text: "Size", value: "product_specification.size", sortable: false },
      { text: "Approved ", value: "approved_qty", sortable: false },
      { text: "Damaged ", value: "damaged", sortable: false },
      { text: "Not Found ", value: "not_found", sortable: false },
    ],
    orders: [],
    loading: true,
    approvedQty: 0,
    damagedqty: 0,
    notfoundQty: 0,
    TransferInvoiceArray: [],
    AfterTransferInvoiceArray: [],
    invoiceinfoarray: [],
    invoiceinfoarrayafter: [],
    requested_pending_product: [],
    ApprovePendindReqdialog: false,
    approved_qty: 0,
    req_details: [],
    danage_qty: 0,
    not_found_qty: 0,

    mid: {},
  }),

  methods: {
    initialize() {
      axios
        .get(`manager/unattend_products/${this.user.user_id}`)
        .then((response) => {
          this.pending_product = response.data.ware_house[0].data;
        });

      // Get all approved invoices of this admin
      axios
        .get(`manager/get_house_product/1/${this.$route.query.id}`)
        .then((response) => {
          if (response.data.success) {
            this.TransitProductArray = response.data.data;
            this.loading = false;
          }
        });

      axios
        .get(`manager/details_house_product/1/${this.$route.query.id}`)
        .then((response) => {
          if (response.data.success) {
            this.AllAdminProductArray = response.data.data;
            this.loading = false;
          }
        });

      axios.get("manager/transferable_list").then((response) => {
        if (response.data.success) {
          this.AllWarehouseList = response.data.data;
          this.AllWarehouseList.forEach((element, index) => {
            element.value = index;
          });

          // console.log("All warehouse list data dtata", this.AllWarehouseList);
        }
      });

      axios
        .get(`manager/all_setter_transfer/1/${this.$route.query.id}`)
        .then((response) => {
          if (response.data.success) {
            this.TransferInvoiceArray = response.data.data;
          }
        });
      axios
        .get(`manager/pending_trans_req/1/${this.$route.query.id}`)
        .then((response) => {
          this.requested_pending_product = response.data.data;
        });
    },

    // Details of an Approved Product
    viewApprove(item) {
      this.dialogapproved = true;
      Object.assign(this.approvedchild_details, item);
    },

    // Details of an Product
    view(item) {
      this.dialog = true;
      Object.assign(this.child_details, item);
    },
    SaveApprove(child_details) {
      this.child_details.status_data.forEach((element) => {
        element.quantity = parseInt(element.quantity);
      });
      let datas = {
        inv_rep_id: this.child_details.inv_rep_id,
        manager_id: this.user.user_id,
        status_data: this.child_details.status_data,
      };

      var sum = 0;

      for (var i = 0; i < this.child_details.status_data.length; i++) {
        sum += parseInt(this.child_details.status_data[i].quantity);
      }

      // console.log(sum);
      if (this.child_details.quantity < sum) {
        this.text = "please decrease Quantity";
        this.color = "red";
        this.snackbar = true;
      } else {
        axios
          .post("manager/product_insert/", datas)
          .then((response) => {
            if (response.data.success) {
              this.pending_product = this.pending_product.filter(
                (contact) => contact.id != child_details.id
              );
              this.dialog = false;
              this.ShareProductmodel = false;
              this.text = response.data.message;
              this.color = "success";
              this.snackbar = true;
              window.location.reload();
            } else {
              this.text = response.data.message;
              this.color = "red";
              this.snackbar = true;
            }
          })
          .catch((err) => {
            this.text = "Something went wrong !!";
            //this.text = response.data.message;
            this.color = "error";
            this.snackbar = true;
          });
      }
    },

    closedialog() {
      this.dialog = false;
    },
    CancelApprove(child_details) {
      this.dialog = false;
    },
    savetransit(selected) {
      this.Transfersavedialog = true;
    },
    SaveApproveInvoice(selected) {
      this.selected.forEach((element) => {
        element.requested_qty = parseInt(element.requested_qty);
      });

      let datas = {
        request_setter: this.request_setter,
        request_getter: this.trnasferob.shop_mod_id,
        requestee_user: this.user.user_id,
        requested_products: this.selected,
      };
      // console.log("this is after click", datas);
      axios.post("manager/transfer_request/", datas).then((response) => {
        if (response.data.success) {
          this.text = response.data.message;
          this.color = "success";
          this.snackbar = true;
          this.Transfersavedialog = false;
          window.location.reload();
        }
      });
    },
    CancelInvoice() {
      this.Transfersavedialog = false;
    },
    viewTranferInvoice(item) {
      let routeData = this.$router.resolve({
        name: `/merchant-managerdashboard/pages/own_transfer_invoice/${item.trans_id}`,
      });
      routeData.href = `/merchant-managerdashboard/pages/own_transfer_invoice/?id=${item.trans_id}`;
      window.open(routeData.href, "_blank");
    },
    // This is Warehouse to warehouse Trnsfer Product Approval dialog Open
    ApprovePendingReq(item) {
      this.ApprovePendindReqdialog = true;
      Object.assign(this.mid, item);

      axios
        .get(`manager/specific_transfer/${item.transfer_id}`)
        .then((response) => {
          this.req_details = response.data.data;
          this.req_details.forEach((element) => {
            element.approved_qty = 0;
            element.danage_qty = 0;
            element.not_found_qty = 0;
          });
        });
    },
    // This is Warehouse to warehouse Trnsfer Product Approval Action
    SaveApproveReq(status) {
      this.req_details.forEach((element) => {
        element.specification_id = element.specification_id;
        element.approved_user = this.user.user_id;
        element.approved_qty = parseInt(element.approved_qty);
        element.damaged_qty = parseInt(element.danage_qty);
        element.not_found_qty = parseInt(element.not_found_qty);
      });
      let datas = {
        status: status,
        transfer_id: this.mid.transfer_id,
        approve_data: this.req_details,
      };
      axios
        .post("manager/transfer_product_attend/", datas)
        .then((response) => {
          if (response.data.success) {
            this.text = response.data.message;
            this.color = "success";
            this.ApprovePendindReqdialog = false;
            window.location.reload();
          } else {
            this.text = response.data.message;
            this.color = "error";
          }
          this.snackbar = true;
        })
        .catch((err) => {
          this.text = response.data.message;
          this.color = "error";
          this.snackbar = true;
        });
    },
    CancenApprovepR() {
      this.ApprovePendindReqdialog = false;
    },
  },

  created() {
    this.request_setter = "wh_" + this.$route.query.id;
    this.user = JSON.parse(localStorage.getItem("user"));
    this.initialize();
  },
};
</script>


<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>




